<template>
  <div>
    <Navbar :Categories="categories" :SubCategories="subCategories" :user="user" :cart="cart" :Error="errors"/>
    <main>
      <div class="home mx-auto">
        <router-view :key="$route.path"></router-view>
      </div>
    </main>
    <ServiceArea></ServiceArea>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import ServiceArea from "@/components/Footer/FooterArea.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import {useRouter} from "vue-router";

export default {
    components: {
      Navbar,
      ServiceArea
    },
    data: () => ({
      store: useStore(),
      router: useRouter(),
      user: {},
      cart: {},
      show: false,
      activeName: 'first',
      categories: [],
      subCategories: [],
      errors: 0
    }),

    created() {
      if (this.store.state.user.token){
        this.getUserData()
      }
    },
    mounted() {
      this.getCategories();
    },
    methods: {
      getCartUSer(){
        store.dispatch('getCart');
      },
      getCategories() {
        store.dispatch('getCategories')
          .then(response => {
            this.categories = response.data.categories.filter(category => category.parent_id === 1)
            this.subCategories = response.data.categories
          })
          .catch(error => {
            console.error('Error fetching Categories:', error);
            this.errors = this.message
          });
      },
      getUserData() {
        store.dispatch("getUser").then(() => {
          if (this.store.state.user.token){
            this.user = computed(() => store.state.user.data);
          }else{
            this.user = computed(() => '');
          }
        })
        .catch( (err) => {
          console.log(err)
        })
        store.dispatch("getCart")
        .then( () => {
          if (this.store.state.user.token){
            this.cart = computed( () => store.state.user.cart)
          }else{
            this.cart = computed( () => '')
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    },
    setup() {
        let message = "Actualmente no estamos disponibles, ¡Gracias por tu preferencia!"

        return { message };
    },
}

</script>