<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4">
            <div class="axil-signin-form-wrap" style="margin-top: 50% !important;">
                <div class="">
                    <h3>Es necesario verificar tu número de móvil por SMS para garantizar la seguridad de tus datos personales contra accesos no autorizados</h3>
                    <BCardGroup deck>
                        <h5 class="text-h5"><label style="color: #ca8a04;margin-top:5px;margin-bottom:5px;" for="">Modo desarrollo. Ingresa = 111111</label></h5>
                        <BCard v-if="sendSMS"
                            header="Paso 1"
                            header-tag="header"
                        >
                            <BCardText>
                                <div>
                                    <h2 class="text-center">Ingresa tu numero de telefono</h2>
                                </div>
                                <ul v-if="isLoading">
                                    <div>
                                        <b-spinner variant="danger" class="position-loader" type="grow"
                                            label="Loading..."></b-spinner>
                                    </div>
                                </ul>
                                <Form @submit="onSubmit">
                                    <div class="form-group pt-4">
                                        <label class="mt-4">Numero de telefono <span class="required">*</span></label>
                                        <Field name="phone" @input="limitInput" v-model="phone" type="number" :rules="validPhone" :disabled="sendCode"/>
                                        <ErrorMessage name="phone" style="color: red"/>
                                    </div>
                                    <button class="btn-bg-primary" :disabled="sendCode" @click="validationSMS()">Obtener codigo</button>

                                </Form>
                            </BCardText>
                        </BCard>

                        <BCard header-tag="header" v-if="sendViewCode">
                            <template #header>
                                <h6 class="mb-0">Paso 2</h6>
                            </template>
                            <BCardText>
                                <div @click="returnView()" class="text-right" style="text-align: right;font-size: 12px;color: #0099cc;">Regresar</div>
                                <div>
                                    <h2>Ingresa el codigo que recibiste</h2>
                                </div>
                            </BCardText>
                            <div class="form-group pt-4">
                                <label class="mt-4">Codigo: <span class="required">*</span></label>
                                <input v-model="code" name="code" type="text" :rules="validCode" autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*"/>
                                <ErrorMessage name="code" style="color: red"/>
                            </div>
                            <button @click="verifyCode()" class="axil-btn btn-bg-primary viewcart-btn">Verificar cuenta</button>
                            <div class="pt-3">
                                <BButton variant="outline-primary" :disabled="resendCode" @click="validationSMS()">Re-enviar codigo <span v-if="timerCount !== 0">{{ timerCount }}</span></BButton>
                            </div>
                        </BCard>
                    </BCardGroup> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import store from '@/store'
import { useToast } from "vue-toastification";

export default {
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data: () => ({
        sendCode: false,
        sendViewCode: false,
        timerCount: 0,
        resendCode: true,
        phone: '',
        code: '',
        toast: useToast(),
        sendSMS: true,
        isLoading: false
    }),
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.resendCode = false
                }
            }
        }

    },
    computed: {
        remainingCharacters() {
            return 10 - this.phone.length;
        },
    },
    methods: {
        validPhone(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.toString().length !== 10) {
                return 'Debe contener 10 Numeros';
            }
            return true;
        },
        validCode(val) {
            if (!val) {
                return 'Campo requerido';
            }
            if (val.toString().length !== 6) {
                return 'Debe contener 6 Numeros';
            }
            return true;

        },
        onSubmit(values){
            this.isLoading = true;
            this.sendCode = true;
            this.timerCount = 60;
            console.log(values);
        },
        verifyCode() {
            store.dispatch('validateCode', {
                otp: this.code
            })
            .then((res) => {
                console.log("codes", res)
            }).catch(() => {
            });
        },
        requestCode(){
            //axios request
            this.timerCount = 60;
        },
        validationSMS() {
            this.isLoading = true;
            store.dispatch('validationSMS', {
                phone: this.phone
            })
            .then(response => {
                console.log("loading", this.isLoading)
                console.log('Respuesta del API:', response);
                this.toast.success(response.message)
                if(response.payload.DEV_MODE){
                    this.sendViewCode = true;
                    this.sendSMS = false;
                }
            })
            .catch(error => {
                console.error('Error en la solicitud:', error);
            });
        },
        limitInput() {
            if (this.phone.length > 10) {
                this.phone = this.phone.slice(0, 10);
            }
        },
        returnView(){
            this.sendViewCode = false;
            this.sendCode = false;
            this.sendSMS = true;
        }
    }
}
</script>
<style scoped>
    span.required {
        color: red;
    }

    input[name=phone]:disabled {
        background: #dddddd;
    }
    button:disabled,
    button[disabled]{
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #FFFF;
    }

    button {
        border: 1px solid #0066cc;
        background-color: #0099cc;
        color: #ffffff;
        padding: 5px 10px;
    }
    .axil-signin-form-wrap {
        width: 100%;
        height: calc(100vh - 180px);
        overflow-y: auto;
        display: block !important;
        margin: 180px -30px -30px;
    }
    .text-h5 {
        background: #fefce9;
        border: 2px solid yellow;
        border-radius: 5px;
    }
</style>