import { createRouter, createWebHistory } from 'vue-router'
import SignInView from '../views/Auth/SignInView'
import SignUpView from '../views/Auth/SignUpView'
import WelcomeView from '../views/WelcomeView'
import MyAccount from '../views/Profile/MyAccount'
import MyOrders from '../views/Profile/MyOrders'
import WishList from '../views/Profile/WishList'
import MyAddresses from '../views/Profile/MyAddresses'
import MyProfile from '../views/Profile/MyProfile'
import MyReviews from '../views/Profile/MyReviews'
import MyTickets from '../views/Profile/MyTickets'
import OrderDetail from '../views/Orders/OrderDetail'
import TicketDetail from '../views/Tickets/TicketDetail'
import IndexView from '../views/IndexView'
import NotFound from '../views/NotFoundView'
import LogtoView from '../views/ErrorLogtoView.vue'
import ProductShow from '../views/Product/Show.vue'
import CategoryView from '../views/Categories/CategoryView.vue'
import MasterLayout from '@/components/Layouts/MasterLayout.vue'
import AuthLayout from '@/components/Layouts/AuthLayout.vue'
import CheckOutLayout from '@/components/Layouts/CheckOutLayout.vue'
import CheckOutView from '@/views/Checkout/Checkout.vue'
import CheckOutCart from '@/views/Checkout/Cart.vue'
import TwoFactorAuth from '@/views/Auth/TwoFactorAuth/TwoFactorView.vue'
import SearchResults from '@/views/Search/SearchResults.vue'
import PoliticasView from '../views/PoliticasView'
import FaqView from '../views/FaqView.vue'
import CondicionesView from '../views/CondicionesView'
import PurchaseCompleted from '../views/Orders/PurchaseCompleted'
import store from '@/store'
import LogtoCallback from '@/views/Auth/LogTo/LogtoCallback'
import CreateTicket from '../views/Profile/CreateTicket'

const routes = [
  {
    path: '/',
    redirect: '/index',
    component: MasterLayout,
    meta: {requiresAuth: false},
    children: [
      {
        path: '/index',
        name: 'Index',
        component: IndexView,
      },
      {
        path: "/:id",
        name: "Show",
        component: ProductShow,
        props: true
      },
      {
        path: "/category/:slug",
        name: "Category",
        component: CategoryView,
        props: true
      },
      {
        path: "/politicas",
        name: "Politicas",
        component: PoliticasView,
        props: true
      },
      {
        path: "/faq",
        name: "FAQ",
        component: FaqView,
        props: false
      },
      {
        path: "/condiciones",
        name: "Condiciones",
        component: CondicionesView,
        props: true
      },
      {
        path: "/results",
        name: "Search Results",
        component: SearchResults,
      },
      {
        path: "/checkout/success",
        name: "Purchase Completed",
        component: PurchaseCompleted,
      },
    ],
  },
  {
    path: '/',
    redirect: '/customer/login',
    component: MasterLayout,
    meta: {requiresAuth: true},
    children: [
      {
        path: "/my-profile",
        name: "Profile",
        component: MyAccount,
      },
      {
        path: "/my-orders",
        name: "Orders",
        component: MyOrders,
      },
      {
        path: "/wishlist",
        name: "WishList",
        component: WishList,
      },
      {
        path: "/my-addresses",
        name: "Addresses",
        component: MyAddresses,
      },
      {
        path: "/order-detail/:id",
        name: "Order Detail",
        component: OrderDetail,
      },
      {
        path: "/ticket-detail/:id",
        name: "Ticket Detail",
        component: TicketDetail,
      },
      {
        path: "/customer/account/profile",
        name: "My Profile",
        component: MyProfile,
      },
      {
        path: "/reviews",
        name: "My Reviews",
        component: MyReviews,
      },
      {
        path: "/tickets",
        name: "Tickets",
        component: MyTickets,
      },
      {
        path: "/customer/2fa",
        name: "TwoFactorAuth",
        component: TwoFactorAuth,
      },
      {
        path: "/customer/account/tickets/create",
        name: "Create Ticket",
        component: CreateTicket,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    meta: {isGuest: true},
    children: [
      {
        path: "/customer/login",
        name: "logout",
        component: SignInView,
      },
      {
        path: "/customer/login",
        name: "Sign In",
        component: SignInView,
      },
      {
        path: "/callback",
        name: "Logto Callback",
        component: LogtoCallback
      },
      {
        path: "/welcome",
        name: "Welcome",
        component: WelcomeView,
        props: true
      },
    ],
  }, 
  {
    path: '/customer/',
    redirect: '/index',
    component: CheckOutLayout,
    meta: {requiresAuth: true},
    children: [
      {
        path: '/checkout',
        name: 'Checkout',
        component: CheckOutView,
      },
      {
        path: "/checkout/cart",
        name: "Checkout-Cart",
        component: CheckOutCart,
      },
    ],
  },
  // {
  //   path: '/product-detail/:cate',
  //   name: 'Product Detail',
  //   component: ProductDetail
  // },
  // {
  //   path: '/sign-up',
  //   name: 'Sign Up',
  //   component: SignUpView
  // },
  // {
  //   path: '/cart',
  //   name: 'Cart',
  //   component: CartView
  // },
  // {
  //   path: '/my-account',
  //   name: 'My Account',
  //   component: MyAccountView
  // },
  // {
  //   path: '/checkout',
  //   name: 'Checkout',
  //   component: CheckoutView
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot Password',
  //   component: ForgotPasswordView
  // },
  // {
  //   path: '/wishlist',
  //   name: 'Wish List',
  //   component: WishListView
  // },
  // {
  //   path: '/accesories',
  //   name: 'Accesories Category',
  //   component: AccesoriesCategory
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/support',
    name: 'LogtoError',
    component: LogtoView
  },
  {
    path: "/customer/register",
    name: "Sign Up",
    component: SignUpView
  },
  // {
  //   path: '/api',
  //   name: 'Api',
  //   component: Api
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) { //to, from, savedPosition
    if (savedPosition) {
      return savedPosition
    } else {
      /*return { left: 0, top: 0 }*/
      return  window.scrollTo(0, 0)
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  
  if(to.meta.requiresAuth && !store.state.user.token){
    next({name: 'Sign In'})
  } else if (store.state.user.token && (to.meta.isGuest)) {
    next({name: 'Index'})
  }
  else{
    next();
  }
})

export default router
