<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4">
            <div class="axil-signin-form-wrap">
                <ul>
                    <div>
                        <b-spinner variant="danger" class="position-loader" type="grow"
                            label="Loading..."></b-spinner>
                    </div>
                </ul>
                <!--button v-if="!logto.isAuthenticated.value" @click="handleSignIn" class="axil-btn btn-bg-secondary sign-up-btn">Sign in</button-->
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import {useRouter} from "vue-router";
import { useToast } from "vue-toastification";
import { mapState } from 'vuex'
import { useLogto } from '@logto/vue';

export default {
        el: '#app',
        name: 'SignInView',
        components: {
        },
        data: () => ({
            email: "",
            password: "",
            router: useRouter(),
            toast: useToast(),
            userDataFromLogTo: null,
        }),
        methods: {
            handleSignIn() {
                // this needs to be externalized:
                this.logto.signIn('https://mobile.mainbitpreproduccion.shop/callback');
                this.logto.clearAccessToken();
                //this.logto.signIn('http://localhost:8080/callback', {scope: 'openid profile email'});
            },
            
            handleLogOut() {
                // this needs to be externalized:
                // this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
                this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
                //this.logto.signOut('http://localhost:8080/');
            },
            async getUserDataFromLogTo() {
                try {
                    // Limpiar el token de acceso antes de iniciar el proceso
                    console.log("Status of isAuthenticated: ", this.logto.clearAccessToken());

                    // Actualizar el estado de autenticación en el store
                    store.state.authenticated = this.logto.isAuthenticated.value;

                    // Si no está autenticado, salimos de la función
                    if (!this.logto.isAuthenticated.value) {
                    this.userDataFromLogTo = null;
                    return;
                    }

                    // Log de la instancia Logto
                    console.log("Mi Logto", this.logto);

                    // Obtener los datos del usuario desde Logto
                    this.userDataFromLogTo = await this.logto.fetchUserInfo();
                    console.log("Datos del usuario desde Logto:", this.userDataFromLogTo);
                    store.state.email = this.userDataFromLogTo.email;

                    // Asegurarse de que los datos del usuario contienen un email
                    console.log("Email desde userDataFromLogTo: ", this.userDataFromLogTo.email);

                    // Obtener el token de acceso para el API
                    const accessToken = await this.logto.getAccessToken('https://api.mainbitpreproduccion.shop');
                    console.log("Access Token:", accessToken);

                    // Obtener el refresh token
                    const refreshToken = await this.logto.getRefreshToken();
                    console.log("Refresh Token:", refreshToken);

                    // Almacenar los tokens en sessionStorage
                    sessionStorage.setItem('TOKEN', accessToken);
                    sessionStorage.setItem('REFRESH_TOKEN', refreshToken);

                    // Actualizar el estado global con los tokens y el correo electrónico
                    store.state.user.token = accessToken;
                    store.state.user.tokenFresh = refreshToken;

                    // Llamar al método userProfile con los datos correspondientes
                    this.userProfile(accessToken, refreshToken, this.userDataFromLogTo.email);

                } catch (error) {
                    // Captura cualquier error que ocurra durante la ejecución del proceso
                    console.error("Error al obtener los datos del usuario desde Logto:", error);
                    if (error.error === 'invalid_grant') {
                        this.logto.clearAccessToken();
                        this.logto.clearAllTokens();
                    }
                    
                    // Establecer userDataFromLogTo a null en caso de error
                    this.userDataFromLogTo = null;

                    // Opcional: Actualizar el estado de error en el store o mostrar un mensaje
                    store.state.error = "No se pudo obtener la información del usuario.";
                } finally {
                    // Esto se ejecutará siempre después de la ejecución del try o catch
                    console.log("Proceso de obtención de datos de Logto finalizado.");
                }
            },
            userProfile(accessToken, refreshToken, email){
                store.dispatch('userProfile', {
                    accessToken: accessToken, 
                    refreshToken: refreshToken,
                    email: email
                })
                .then(() => {
                    this.router.push({ name: 'Profile' });
                    /*if(res === undefined){
                        console.log("entra al registro")
                        this.router.push({ name: 'Sign Up' });
                    }else{
                        this.router.push({ name: 'My Profile' });
                    }*/
                    this.cart();
                    this.getWishlist();
                },
                ).catch((error) => {
                    console.log(error.message);
                });
            },
            cart(){
                this.productsCartTemp.forEach((product) => {
                    console.log("productsCartTemp",product.productId)
                        store.dispatch("addProductToCart", {
                            quantity: parseInt(product.quantity),
                            product_id: product.productId,
                            is_buy_now: 0,
                            inventory_id: 0
                        }).then(() => {
                            const index2 = this.productsCartTemp.findIndex(c => c.productId = product.productId)
                            if (index2 > -1)
                                this.productsCartTemp.splice(index2, 1)
                                this.productsTemp.splice(index2, 1)
                        }).catch((err) => {
                            console.log(err);
                        });
                });
            },
            getWishlist() {
                store.dispatch('getWishlist')
                .then(() => {
                })
                .catch(error => {
                    console.error('Error fetching getWishlist:', error);
                });
            },
        },
        computed: {
            isDisable() {
                return this.email.length > 0 && this.password.length > 0;
            },
            ...mapState(['productsCartTemp','productsTemp'])
        },
        created() {
          this.logto = useLogto();
        },
        mounted() {
            this.getUserDataFromLogTo();
          if(!this.logto.isAuthenticated.value){
            this.handleSignIn();
          }
        }
}
</script>



