<template>
    <div class="tab-pane" id="nav-account" role="tabpanel" style="padding: 0 20px;">
        <div class="col-lg-9">
            <br><br>
            <!-- Start Breadcrumb Area  -->
            <div class="axil-breadcrumb-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-8">
                            <div class="inner">
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Index' }">INICIO</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item active" aria-current="page">TICKETS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Breadcrumb Area  -->
            <div>
                <ul v-if="isLoading">
                    <div>
                        <b-spinner variant="danger" class="position-loader" type="grow"
                            label="Cargando..."></b-spinner>
                    </div>
                </ul>
            </div>
            <div class="axil-dashboard-account" style="margin-top: 30%;">
                <div class="account-details-form" v-for="(row, key) in data" :key="key">
                    <div class="row">
                        <div class="col-lg-6" style="margin-top: 10px">
                            <div class="row text-center">
                                <div class="col-12">
                                    <h3>Crear Ticket</h3>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Asunto</label>
                                <input v-model="form.subject" type="text" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Detalles</label>
                                <textarea v-model="form.body" type="text" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group text-dark">
                                <label>Orden:</label>
                                <select v-model="form.order_id" id="orders">
                                    <option disabled value="">Seleccion el pedido</option>
                                    <option v-for="order in orders" :key="order.id" :value="order.id">
                                    # {{ order.id }} 
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Razon del ticket:</label>
                                <select v-model="form.tickets_reason_id" id="reasons" >
                                    <option disabled value="">Seleccion la razon del ticket</option>
                                    <option v-for="reason in reasons" :key="reason.id" :value="reason.id">
                                    {{ reason.reason }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <b-button @click="ticket()" style="margin: auto;width: 40%;top: -16px;" :disabled="isFormValid" variant="danger">Guardar</b-button>
                        <b-button @click="cancel()" style="margin: auto;width: 40%;top: -16px;" variant="info">Cancelar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store'
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import {useRouter} from "vue-router";

export default {
    name: 'UserProfile',
    data() {
        return {
            toast: useToast(),
            form:{
                subject:'',
                body:'',
                order_id:'',
                tickets_reason_id:'',
                customer_id: ''
            },
            options: [  
                { value: '', text: '' },
            ],
            data: [],
            showModal: false,
            isDisabled: false,
            isDisabled2: false,
            showBtn1: false,
            router: useRouter(),
            reasons: [],
            orders: ''
        }
    },
    mounted(){
        if (store.state.user.token){
            this.getProfile();
        }
        this.getReasons();
    },
    computed: {
        ...mapState(['isLoading']),
        isFormValid() {
            // Retorna true si ambos inputs están llenos
            return !this.form.subject || !this.form.body || !this.form.order_id || !this.form.tickets_reason_id
        }
    },
    methods: {
        getReasons(){
            store.dispatch('getTicketsReasons')
            .then((res) => {
                this.reasons = res.data;
                 
                this.orders = store.state.user.orders
                return res
            })
            .catch(error => {
                return error
            });
        },
        updateValueFirstName(event) {
            this.row.first_name = event.target.value;
        },
        updateValueLastName(event) {
            this.row.last_name = event.target.value;
        },
        updateValueGender(event) {
            this.row.gender = event.target.value;
        },
        updateValueDateOfBirth(event) {
            this.row.date_of_birth = event.target.value;
        },
        getProfile() {  
            this.isLoading = true;
            store.dispatch('getUser')
            .then((res) => {
                this.data.push(res.data.userDetails);
                this.form.customer_id = res.data.userDetails.id
            })
            .catch(error => {
                console.error('Error fetching getWishlist:', error);
            });
        },
        cancel() {
            this.isDisabled = !this.isDisabled;
            this.btn1Disabled = true;
        },
        ticket() { 
            console.log("el id del usuario es: ", this.form)
            this.isLoading = true;
            store.dispatch('createTicket', {
                subject: this.form.subject,
                body: this.form.body,
                tickets_reason_id: this.form.tickets_reason_id,
                order_id: this.form.order_id,
                customer_id: this.form.customer_id
            })
            .then((res) => {
                this.toast.success(res.data.message)
                this.router.push({ name: 'Tickets' });
            })
            .catch(error => {
                console.error('Error fetching getWishlist:', error);
            }); 
        },
        edit1() { 
            this.isDisabled = false;
            this.btn1Disabled = false;
        },
        toggleDisabled() {
            this.isDisabled = !this.isDisabled;
        },
        toggleDisabled2() {
            this.isDisabled2 = !this.isDisabled2;
        },
        btn1Disabled() {
            this.showBtn1 = !this.showBtn1;
        },
    },
}
</script>

<style scoped>
    .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
    }

    .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 18px;
    cursor: pointer;
    }
</style>