<template>
    <br><br>
    <main class="main-wrapper" style="margin-top: 25%;">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Index' }">INICIO</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">{{ slug.toUpperCase() }}</li>
                            </ul>
                            <!--h1 class="title">Explorar todos los productos</h1-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Breadcrumb Area  -->

        <!-- Start Shop Area  -->
        <div class="axil-shop-area axil-section-gap bg-color-white">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="header-navbar text-left">
                                <div class="header-main-nav" :class="showNavMenu ? 'open' : ''">
                                    <!-- Start Mainmanu Nav -->
                                    <nav class="mainmenu-nav" style="background:#fff;left:0;z-index:9;width:55%;">
                                        <button class="mobile-close-btn mobile-nav-toggler" @click="openFilters()"
                                            style="top:55px">
                                            <i class="fas fa-times"></i>
                                        </button><br><br><br><br>
                                        <div class="col-lg-12">
                                            <div class="axil-shop-top mb--40">
                                                <div
                                                    class="align-items-center justify-content-lg-end justify-content-between">
                                                    <span class="filter-results">Filtrar por:</span><br><br>
                                                    <div v-for="(category, atr) in categories" :key="atr"
                                                        class="single-select" @change="setAttributeLabel($event)">
                                                        <br>
                                                        <h6 class="title">{{ category.name }}:</h6>
                                                        <select v-model="attrOptions[category.name]">
                                                            <option v-for="(item, bx) in category.values" :key="bx" for=""
                                                                :label="item.label" :value="item.label">
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                    <!-- End Mainmanu Nav -->
                                </div>
                                <div class="header-action">
                                    <div class="row">
                                        <div class="col-9">
                                            <div class="axil-shop-top mb--40">
                                                <div
                                                    class="align-items-center justify-content-lg-end justify-content-between">
                                                    <br>
                                                    <span class="filter-results">Mostrando {{ products.length }}
                                                        productos</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="axil-shop-top mb--40">
                                                <div class="d-lg-none">
                                                    <button title="Filtros" class="product-filter-mobile filter-toggle"
                                                        @click="openFilters()">
                                                        <i class="fas fa-filter"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showMask" class="closeMask" @click="openFilters()"></div>
                            </div>
                        </div>
                        <!-- End .row -->
                        <div class="row row--15">
                            <ul>
                                <div v-for="(prod, index) in products" :key="index" class="col-xl-4 col-sm-6">
                                    <div v-if="isLoading">
                                        <PlaceHolder></PlaceHolder>
                                    </div>
                                    <div class="axil-product product-style-one mb--30">
                                        <div class="thumbnail">
                                            <Carousel>
                                                <Slide v-for="slide in prod.images" :key="slide">
                                                    <div class="carousel__item">
                                                        <router-link :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                                                            <img :src="slide.path" alt="" style="height: 180px;width: 130px;">
                                                        </router-link>
                                                    </div>
                                                </Slide>
                                                <template #addons>
                                                    <Navigation />
                                                    <!--Pagination / -->
                                                </template>
                                            </Carousel>
                                            <div class="label-block label-right">
                                                <div class="product-badget">
                                                    <a @click.prevent="addWishlist(prod.id)">
                                                        <i class="far fa-heart"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="label-block label-right" v-if="prod.inventory.mb_special_price"
                                                style="margin-top: 15%;">
                                                <div class="product-badget" style="background: #38d10b;">OFERTA</div>
                                            </div>
                                            <div class="label-block label-left">
                                                <div class="product-badget" v-if="prod.simple_fields.refurbished === 1"
                                                    style="background: #000;">
                                                    REACONDICIONADO</div>
                                            </div>
                                        </div>
                                        <div class="product-content">
                                            <div class="inner">
                                                <h5 class="title">
                                                    <router-link
                                                        :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                                                        <div
                                                            style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: center;">
                                                            {{ prod.simple_fields.name }}
                                                        </div>
                                                    </router-link>
                                                </h5><br>
                                                <div class="product-price-variant">
                                                    <div v-if="prod.inventory.mb_special_price">
                                                        <span class="price current-price" style="font-size: 18px;">
                                                            {{ $filters.money(prod.inventory.mb_special_price) }}
                                                        </span><br>
                                                        <span class="price old-price" style="font-size: 18px;">
                                                            {{ $filters.money(prod.inventory.mb_price) }}
                                                        </span><br><br>
                                                    </div>
                                                    <div v-else style="margin-bottom: 57px;">
                                                        <span class="price current-price" style="font-size: 18px;">
                                                            {{ $filters.money(prod.inventory.mb_price) }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                            <!-- End Single Product  -->
                        </div>
                        <div class="text-center pt--20">
                            <a href="#" class="axil-btn btn-bg-lighter btn-load-more">Load more</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End .container -->
        </div>
        <!-- End Shop Area  -->
    </main>
</template>

<script>
import store from '@/store'
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import PlaceHolder from '@/components/PlaceHolder/CategoryProducts.vue'
import { mapState } from 'vuex'
import { useToast } from "vue-toastification";

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
        PlaceHolder
    },
    props: ['slug'],
    data: () => ({
        products: [],
        categories: [],
        attrOptions: {},
        attrKeys: [],
        attrValues: [],
        originalProducts: [],
        showNavMenu: false,
        showMask: false,
        toast: useToast(),
    }),
    mounted() {
        this.getAttibutes();
    },
    computed: {
        ...mapState(['isLoading'])
    },
    methods: {
        addWishlist(id_product) {
            if (store.state.user.token) {
                store.dispatch("addProductToWishlist", {
                    productId: id_product
                })
                    .then(() => {
                        store.dispatch("getWishlist")
                        this.toast.success('Artículo añadido a favoritos')
                    }).catch((err) => {
                        console.log(err);
                    });
            } else {
                this.toast.error('Inicia sesión para poder realizar esta acción')
            }
        },
        getAttibutes() {
            store.dispatch('productCategory', this.$route.params.slug)
                .then((res) => {
                    this.categories = res.data.category.attributes;
                    this.products = res.data.category.products;
                    this.originalProducts = res.data.category.products;
                }).catch((err) => {
                    console.log(err);
                });
        },
        setAttributeLabel() {
            this.attrKeys = Object.keys(this.attrOptions)
            this.attrValues = Object.values(this.attrOptions);
            this.products = this.filterProducts();

        },

        filterProducts() {
            let products = []
            this.products = this.originalProducts
            this.products.forEach((product) => {
                product.attributes.filter(attr => {
                    if (this.attrValues.includes(attr.value)) {
                        products.push(product);
                    }
                })
            });
            return JSON.parse(JSON.stringify(this.removeDuplicates(products)))

        },
        removeDuplicates(data) {
            return [...new Set(data)]
        },
        openFilters() {
            this.showNavMenu = !this.showNavMenu
            this.showMask = !this.showMask
        },
    },
}

</script>

<style>
.carousel__item {
    min-height: 0;
    width: 100%;
    background-color: transparent;
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
    color: inherit;
    text-decoration: auto;
    text-align: right;
    color: #174474;
    word-break: break-word;
    font-family: var(--font-secondary);
    line-height: 1.3;
}

.axil-product .product-content .product-price-variant span.price {
    font-size: 20px;
}

h2,
.h2 {
    font-size: calc(1.325rem + 0.9vw);
    text-align: justify;
    padding: 0 20px;
}
</style>