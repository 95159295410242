<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Tickets' }">TICKETS</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">#{{ id }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="nav-orders" role="tabpanel" style="padding: 20px 20px;">
            <div class="axil-dashboard-order">
                <div class="product-table-heading">
                    <h1 class="title">Detalle del ticket.</h1>
                </div>
                <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
                </div>
                <div v-else class="card-order c1 color-order">
                    <div v-for="(ticket) in tickets" :key="ticket.id">
                        <div class="row">
                            <div class="col-8 order-date">{{ $filters.inDate(ticket.created_at) }}</div>
                            <div class="col-4 order-status"><b>#{{ id }}</b></div>
                        </div><br>
                        <div class="row">
                            <div class="col-7">
                                <div v-for="(reason, rs) in reasons" :key="rs">
                                    <h3 class="data text-dark" v-if="reason.id == ticket.tickets_reason_id">
                                        {{ reason.reason }}
                                    </h3>
                                </div>
                            </div>
                            <div class="col-5">
                                <h5 class="data" style="text-align: right;">
                                    {{ ticket.order_id }}
                                </h5>
                            </div>
                        </div>   
                        <hr>
                        <div class="row">
                            <div class="col-7">
                                <h3 class="data text-dark">
                                    {{ ticket.body }}
                                </h3>
                            </div>
                            <div class="col-5">
                                <h5 class="data" style="text-align: right;">
                                    {{ ticket.status }}
                                </h5>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import store from "@/store";
import { useRouter } from "vue-router";
import PlaceHolder from '@/components/PlaceHolder/OrderDetail.vue'
import { mapState } from "vuex";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        originalTickets: [],
        tickets: [],
        id: 0,
        reasons: []
    }),
    components: {
        PlaceHolder
    },
    props: ['ID'],
    mounted(){
        this.getTickets();
        this.getReasons();
        console.log("idddd", this.id)
    },
    created() {
        this.id = this.$route.params.id
    },
    computed: {
        ...mapState(['isLoading']),
    },
    methods: {
        getTickets() {
            this.id = this.$route.params.id
            store.dispatch("getTickets")
            .then((response) => {
                /*this.tickets = response.data.filter(category => category.id === this.id)
                console.log("jsjsjss", this.tickets)*/
                this.originalTickets = response.data
                this.tickets = this.dataTicket();
                console.log("jsjsjsjss", this.tickets)
            })
            .catch((err) => {
                console.log(err)
            })
        },
        getReasons(){
            store.dispatch('getTicketsReasons')
            .then((res) => {
                this.reasons = res.data;
                return res
            })
            .catch(error => {
                return error
            });
        },
        dataTicket() {
            let tickets = [];
            this.tickets = this.originalTickets
            this.tickets.filter(ticket => {
                if (parseInt(this.id) === parseInt(ticket.id)) {
                    tickets.push(ticket);
                }
            });
            return tickets
        },
    }
}
</script>
<style>
.color-order {
    background: #f8f9fa;
}

body{margin-top:20px;}

.steps {
    border: 1px solid #e7e7e7
}

.steps-header {
    padding: .375rem;
    border-bottom: 1px solid #e7e7e7
}

.steps-header .progress {
    height: .25rem
}

.steps-body {
    display: table;
    table-layout: fixed;
    width: 100%
}

.step {
    display: table-cell;
    position: relative;
    padding: 1rem .75rem;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    border-right: 1px dashed #dfdfdf;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    text-align: center;
    text-decoration: none
}

.step:last-child {
    border-right: 0
}

.step-indicator {
    display: block;
    position: absolute;
    top: .75rem;
    left: .75rem;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    background-color: #fff;
    font-size: .875rem;
    line-height: 1.375rem
}

.has-indicator {
    padding-right: 1.5rem;
    padding-left: 2.375rem
}

.has-indicator .step-indicator {
    top: 50%;
    margin-top: -.75rem
}

.step-icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    margin-bottom: .75rem;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    color: #888
}

.step:hover {
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none
}

.step:hover .step-indicator {
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    border-color: transparent;
    background-color: #f4f4f4
}

.step:hover .step-icon {
    color: rgba(0, 0, 0, 0.9)
}

.step-active,
.step-active:hover {
    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
    cursor: default
}

.step-active .step-indicator,
.step-active:hover .step-indicator {
    border-color: transparent;
    background-color: #5c77fc;
    color: #fff
}

.step-active .step-icon,
.step-active:hover .step-icon {
    color: #5c77fc
}

.step-completed .step-indicator,
.step-completed:hover .step-indicator {
    border-color: transparent;
    background-color: rgba(51, 203, 129, 0.12);
    color: #33cb81;
    line-height: 1.25rem
}

.step-completed .step-indicator,
.step-completed .step-icon {
    border-color: transparent;
    color: #5c77fc;
    line-height: 1.25rem
}

.step-completed .step-indicator .feather,
.step-completed:hover .step-indicator .feather {
    width: .875rem;
    height: .875rem
}

@media (max-width: 575.98px) {
    .steps-header {
        display: none
    }
    .steps-body,
    .step {
        display: block
    }
    .step {
        border-right: 0;
        border-bottom: 1px dashed #e7e7e7
    }
    .step:last-child {
        border-bottom: 0
    }
    .has-indicator {
        padding: 1rem .75rem
    }
    .has-indicator .step-indicator {
        display: inline-block;
        position: static;
        margin: 0;
        margin-right: 0.75rem
    }
}

.bg-secondary {
    background-color: #f7f7f7 !important;
}
</style>