<template>
    <div class="axil-signin-area">
        <div class="row">
            <div class="col-lg-12 offset-xl-4">
                <div class=""><br><br><br><br>
                    <div class="col-sm-4">
                        <img src="@/assets/images/logo/logo.jpeg" alt="logo">
                    </div>
                    <div class="axil-signin-form">
                        <div class="row">
                            <div class="singin-header-btn">
                                <p>¡Bienvenido!</p>
                            </div>
                        </div>
                        <div class="singin-form"><br>
                            <p class="b2">Ya casi estamos ahi. Solo un par de preguntas extras para conocerte mejor.</p><br>
                            <div class="form-group">
                                <label>¿Cuál es tu nombre?</label>
                                <b-form-input :state="validName" type="text" class="form-control" name="name" v-model="form.first_name"></b-form-input>
                                <b-form-invalid-feedback :state="validName">
                                </b-form-invalid-feedback>
                                <b-form-valid-feedback :state="validName">
                                </b-form-valid-feedback>
                            </div>
                            <div class="form-group">
                                <label>Apellidos</label>
                                <b-form-input :state="validLastName" type="text" class="form-control" name="last_name" v-model="form.last_name"></b-form-input>
                                <b-form-invalid-feedback :state="validLastName">
                                </b-form-invalid-feedback>
                                <b-form-valid-feedback :state="validLastName">
                                </b-form-valid-feedback>
                            </div>
                            <div class="form-group">
                                <label>¿Cómo te identificas?</label>
                                <select v-model="form.gender" id="options" @input="updateValueGender" :disabled="isDisabled" required>
                                    <option disabled value="">Please select one</option>
                                    <option v-for="option in options" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>¿Cuál es tu fecha de nacimiento?</label>
                                <b-form-input type="date" class="form-control" name="last_name" v-model="form.date_of_birth" required="required"></b-form-input>
                            </div>
                            <div class="form-group">
                                <button variant="danger" style="background-color: #e42935;color: #fff;" @click="register()" class="axil-btn btn-bg-danger submit-btn">Comienza a comprar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useLogto } from '@logto/vue';
    export default {
        name: 'SignUpView',
        components: { },
        data: () => ({
            form: {
                first_name: "",
                last_name: "",
                gender: "",
                date_of_birth: "",
                email: store.state.email,
                logto_sub: ""
            },
            toast: useToast(),
            router: useRouter(),
            options: [  
                { value: 'H', text: 'Hombre' },
                { value: 'M', text: 'Mujer' },
                { value: 'N', text: 'Prefiero no decir' },
            ],
            userDataFromLogTo: null,
        }),
        computed: {
            validName() {
                var name = /^[A-Z]+$/i;
                return name.test(this.form.first_name) && this.form.first_name.length >= 4
            },
            validLastName() {
                var last_name = /^[A-Z]+$/i;
                return last_name.test(this.form.last_name) && this.form.last_name.length >= 4
            },
        },
        methods: {
            async logtoSub(){
                this.userDataFromLogTo = await this.logto.fetchUserInfo();
                this.form.logto_sub = this.userDataFromLogTo['sub'];
            },
            async register(){
                store.dispatch('newCustomer', this.form)
                .then((res) => {
                    this.toast.success(res.data.messageForUser);
                    this.router.push({ name: 'Index' });
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
        mounted(){
            this.logtoSub();
        },
        created() {
          this.logto = useLogto();
        },
    }
</script>